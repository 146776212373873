var protocol = window.location.protocol;
var host     = window.location.host;
var pathname = window.location.pathname.split('/')[1];
var str      =  location.href.toLowerCase();
var base_url = protocol+'//'+host+'/';

$(function(){
	$("ul.pagination").addClass("col pull-right");

  var uri = location.href.toLowerCase();


  $("ul.sidebar-menu > li a").each(function() {

    if (uri.search(this.href.toLowerCase()) > -1) {

      var parents = $(this).parent()


      $(this).parent().removeClass("active");
      $(this).parent().addClass("active");

      $('.treeview-menu > li a').each(function() {


        if (uri.search(this.href.toLowerCase()) > -1) {

          $(this).parent().parent().parent().removeClass("active");
          $(this).parent().parent().parent().addClass("active");
          $(this).removeClass('active')
          $(this).addClass('active')

        }

      });
    }

  });
})

var App = {
  ajaxHeader: function(){
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });
  },

  datatables: function(url, data){
    $('#datatables').DataTable({
      language: {
        url: '//cdn.datatables.net/plug-ins/1.10.9/i18n/Indonesian.json' 
      },
      processing: true,
      serverSide: true,
      ajax: base_url+url,
      columns: data
    });
  },

  select2: function(type, url, selector){

    if(type == 'ajax'){

      function formatRepo (repo) {
        return repo.text
      }

      function formatRepoSelection (repo, nama) {
        return repo.text;
      }

      var id = '';
      if(typeof(selector) != 'undefined'){
        id = selector;
      }else{

        id = '.select2';
      }

      $(id).select2({
        placeholder: "Search ...",
        quietMillis: 100,
        ajax: {
          url: url,
          dataType: 'json',
          delay: 100,
          data: function (params) {
            return {
              term: params.term
            };
          },
          processResults: function (data, page) {
            return {
              results: data.results
            };
          },
          cache: true
        },
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        minimumInputLength: 2,
        templateResult: formatRepo,
        templateSelection: formatRepoSelection,
      });
    }else{
      $('.select2').select2({
        placeholder: "Search ..."
      });
    }

  },

  multiselect: function(){
    $('.multiselect').multiSelect({selectableOptgroup: true});
  },

  datepicker: function(startDate){
    if(typeof(startDate) == 'undefined'){
      var start = "";
    }else{
      var start = "'"+startDate+"'";
    }
    $('.datepicker').datepicker({
      format: "yyyy-mm-dd",
      autoclose: true,
      todayHighlight: true,
      startDate: start,
    });
  },

  autonumeric: function(){

    $('.currency').autoNumeric('init');
  },
  wysihtml5: function(selector){
    $(selector).wysihtml5();
  },
  validateAjax: function(modal, form_id, redirect_url, error_message){

    // load modal
    $(modal).on('loaded.bs.modal', function () {
      var url = $(form_id).data('url');
      var method = $(form_id).data('method');

      $(form_id).validate({
        submitHandler: function(form) {
          $.ajax({
            url: url,
            method: method,
            dataType: 'json',
            data: $(form).serialize(),
            success: function(data){
              if(data.status == true){
                window.location.href = base_url+redirect_url
              }else{
                $('.message').removeClass('hide');
                $('.message').html(data.messages);
              }
            },
            error: function(jqXhr){
              if( jqXhr.status === 422 ) {
                      var errors = $.parseJSON(jqXhr.responseText); //this will get the errors response data.
                      var close = '<a href="#" class="close" data-dismiss="alert">&times;</a>'

                      errorsHtml = '<div class="alert alert-danger">'+close+'<ul>';

                      $.each( errors, function( key, value ) {
                        errorsHtml += '<li>' + value[0] + '</li>';

                      });
                      errorsHtml += '</ul></div';
                      $('.message').removeClass('hide');
                      $('.message').html(errorsHtml);
                    } else {
                      $('.message').removeClass('hide');
                      $('.message').html(error_message);
                    }
                  }
                })
}
});
});

    // close modal
    $(modal).on('hidden.bs.modal', function () {
      $(this).removeData('bs.modal');
    })
  },
  validateSubmit: function(modal, form_id){
    // load modal
    $(modal).on('loaded.bs.modal', function () {
      $(form_id).validate({
        submitHandler: function(form) {
          $(form).submit();
        }
      });
    });

    // close modal
    $(modal).on('hidden.bs.modal', function () {
      $(this).removeData('bs.modal');
    })
  },
  export_report: function(url, data, callback_url){
    $('.button_report').html('');
    $.ajax({
      url: base_url+url,
      method: "POST",
      data: data,
      success: function(res) {
        if (res.status) {

          file = res.file;
          $("<a/>", {
            href: callback_url+"?file="+file,
            target: "_blank",
            text: " Export to excel",
            class: "btn btn-primary",
          })
          .prepend('<i class="fa fa-file-excel-o"></i> ')
          .appendTo(".button_report");
          
        }
      }
    })
  }

}